import React from "react"
import Layout from "../components/layout"
import Fade from 'react-reveal/Fade'
import { graphql } from 'gatsby'
import Img from "gatsby-image";

function Video(props) {
    return (
        <div className="col-md-6 mb-3 mb-0-lg">
            <div className="ratio ratio-16x9">
                <iframe src={props.url} allowfullscreen title={props.title}></iframe>
            </div>
            <p aria-hidden="true" className="mt-3 smaller font-weight-bold">{props.title}</p>
        </div>
    )
}

const Media = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
        <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
        <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
            <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown active" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span> <span className="sr-only">(current)</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="anchor" id="videos"></div>
    <div className="container pt-4 pb-5">
    {/* <div className="text-center mb-3"><a href="https://space.bilibili.com/485439766/?share_source=copy_link&share_medium=ipad&bbid=ZA45FAA212B8CFB340709A7A725C27D7532F&ts=1590683238" target="_blank" rel="noopener noreferrer"><p lang="zh-Hans" className="btn btn-dark gradient">中国观众进入哔哩哔哩在线欣赏</p></a></div> */}
    <div className="text-center mb-3"><a href="https://space.bilibili.com/485439766/?share_source=copy_link&share_medium=ipad&bbid=ZA45FAA212B8CFB340709A7A725C27D7532F&ts=1590683238" target="_blank" rel="noopener noreferrer" className="btn btn-dark" lang="zh-Hans" style={{ background:"linear-gradient(144deg, rgba(241,180,122,1) 0%, rgba(94,9,121,1) 47%, rgba(37,6,43,1) 100%)", border:"none" }}>中国观众进入哔哩哔哩在线欣赏</a></div>
    <Fade>
        <div className="row">
        {data.video.nodes.map(node => (
            <Video
                url={node.data.url}
                title={node.data.title}
            />
        ))}
        </div>
    </Fade>
    </div>

    <div className="anchor" id="photos"></div>
    <div className="container">
    <div className="row mt-5">
        {data.portrait.nodes.map(node => (
            <div className="col-md-6 mb-3 mb-0-lg">
                <Fade>
                <Img fluid={node.data.image.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                </Fade>
            </div>
        ))}
        </div>
        <div className="row mt-5">
        {data.landscape.nodes.map(node => (
            <div className="col-12 col-lg-7 mx-auto mb-3">
                <Fade>
                <Img fluid={node.data.image.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                </Fade>
            </div>
        ))}
        </div>


        <div className="masonry-photo">
        {data.photo.nodes.map(node => (
            <Fade>
            <Img fluid={node.data.image.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="item" />
            </Fade>
        ))}
        </div>
    </div>
    </Layout>
)
export default Media;

export const query = graphql`
{
    video:allAirtable(filter: {table: {eq: "Media"}, data: {section: {eq: "video"}, publish: {eq: true}}}
    sort: {fields: data___name, order: ASC}
    ) {
        nodes {
            data {
                title
                url
            }
        }
    }

    photo:allAirtable(filter: {table: {eq: "Media"}, data: {section: {eq: "photo"}, publish: {eq: true}}}
    sort: {fields: data___name, order: ASC}
    ) {
        nodes {
            data {
                alt
                image {
                    localFiles {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    portrait:allAirtable(
        filter: {table: {eq: "Media"}, data: {section: {eq: "additional"}, publish: {eq: true}, orientation: {eq: "portrait"}}}
        sort: {fields: data___name, order: ASC}
      ) {
        nodes {
            data {
                alt
                image {
                    localFiles {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }

    landscape:allAirtable(
        filter: {table: {eq: "Media"}, data: {section: {eq: "additional"}, publish: {eq: true}, orientation: {eq: "landscape"}}}
        sort: {fields: data___name, order: ASC}
      ) {
        nodes {
            data {
                alt
                image {
                    localFiles {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
}  
`;